import { Component, Vue } from "vue-property-decorator";
import { WorkStatus } from "@/store/modules/activity.store";

@Component
export class HasMyWorkActions extends Vue {
  /**
   * Create a clean activity query based on the  work status
   *
   * @param {number[]} [activityIds=null] - the ids of the activities
   * @returns {MyWorkActivityQuery} the query object
   */
  createMyWorkActivityQuery(workStatus: WorkStatus[] = []) {
    let indexPayload: MyWorkActivityQuery = {};

    if (workStatus.length) {
      indexPayload.or = { work_status: workStatus };
    }

    return indexPayload;
  }

  /**
   * Create a clean task query based on the selected user, task group and search query
   *
   * @param {number | null} [assignedTo=null] Id of the assigned user
   * @param {number | null} [activityId=null] Id of the selected activity
   * @param {string[]} [taskGroup=[]] Optionally selected task groups
   * @param {string} [search=""] Search query based on the task title
   * @returns MyWorkTaskQuery
   */
  createMyWorkTaskQuery(assignedTo: number | null = null, activityId: number | null = null, taskGroup: string[] = [], search: string = "") {
    let query: MyWorkTaskQuery = {};

    if (assignedTo) {
      query["assigned_to.id"] = assignedTo;
    }

    if (activityId) {
      query.activity_id = activityId;
    }

    if (search) {
      query.and ??= { and: {} };
      query.and.or = { title: [`like:*${search}*`, `match:${search}`] };
    }

    if (taskGroup.length) {
      query.and ??= { and: {} };
      query.and.and ??= {};
      query.and.and.or = { category: taskGroup };
    }

    return query;
  }
}
